/*Home Page CSS*/

.banner-top{
  width: 100%;
  height: auto;
  min-height: 500px;
  overflow: hidden;
  position: relative;
}

.info-top {
  position: absolute;
  font-weight: 200;
  font-size: 1.4rem;
  top: calc(0% + 140px);
  padding-left: 5%;
  padding-right: 5%;
  /* left: calc(0% + 10%); */
  text-align: center;
  width: 100%;
  text-shadow: 0.02em 0 lightgray, 0 0.02em lightgray;
}

.info-top p {
  text-transform: uppercase;
}

.banner {
  width: 100%;
  height: auto;
  min-height: 500px;
  overflow: hidden;
  position: relative;
  /* border-bottom: 15px solid #21396c; */
}

.bg-image {
  width: 100%;
  min-width: 900px;
  min-height: 500px;
  height: auto;
  position: absolute;
  /* overflow: hidden; */
}

.color-overlay{
  width: 100%;
  height: 100%;
  background: #2da3c8;
  position: absolute;
  opacity: .15;

  /* border: 3px solid red; */
}

.info-centered {
  position: absolute;
  font-weight: 200;
  color: white;
  top: calc(0% + 30px);
  left: 0;
  text-align: center;
  width: 100%;
  text-shadow: 0.02em 0 lightgray, 0 0.02em lightgray;
}

ul {
  list-style: none;
}

.services-block {
  padding-top: 20px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
  text-align: center;
  color: #1c2769;
  /* background: url('../assets/images/home/grid-background.jpg') repeat; */
  /* background-image: radial-gradient(circle, white, #1e98b6, #32568e); */
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
    
}

.block {
  position: relative;
  width: 30%;
  margin: 2%;
  flex: 1 1 25%;
  /* border-radius: 5%; */
  /* border: 2px solid lightblue; */
}

.color-overlay-grid{
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  opacity: .55;
  border-radius: 10%;
  /* border-radius: 40%; */
  /* border: 3px solid red; */
}

.color-overlay-grid:hover {
  z-index: 1;
  background: black;
  opacity: .2;
  transition: .25s;
  transition-timing-function: linear;
}

.image {
  width: 100%;
  display: flex;
  border-radius: 10%;
  /* border-radius: 40%; */
}

.grid-text {
  position: absolute;
  font-weight: 400;
  font-size: larger;
  color: white; 
  left: 50%;                        
  top: 50%;                         
  transform: translate(-50%, -50%); 
}

@media only screen and (max-width: 700px) {

  .info-top {
    font-size: 1.2rem;
  }

  .services-block{
    padding-left: 1%;
    padding-right: 1%;
      
  }

  .grid-text{
    color: black;                        
    transform: translate(-10%, -50%); 
  }

  .block {
    flex: 1 1 100%;
    /* width: 100%; */
    height: 6rem;
    border-radius: 20%;
  }

  .image{
    /* height: 10rem; */
    /* width: fit-content; */
    height: 6rem;
    width: auto;
    border-radius: 30px;
    /* border-bottom-right-radius: 0px;
    border-top-right-radius: 0px; */
  }
    
  .color-overlay-grid{
    border-radius: 30px;
    opacity: .4;
  }

}


  



 

  
