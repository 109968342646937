.contact-title {
    color: #1c2769;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    text-shadow: 0.02em 0 lightgray, 0 0.02em lightgray;
  }

.contact-form {
    margin-bottom: 40px;
    margin-left: 4%;
    margin-right: auto;
    max-width: 90%;
    align-items: center;
}
  
.form-control {    
    margin: 20px 0;
}
  
.form-control label {
    display: block;
}
  
.form-control input {
    width: 100%;
    height: 40px;
    margin: 5px;
    padding: 3px 7px;
    font-size: 17px;
}
  
.form-control-check {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.form-control-check label {
    flex: 1;
}
  
.form-control-check input {
    flex: 2;
    height: 20px;
}

@media screen and (min-width: 415px){
    .contact-container {
        margin-left: 5%;
        margin-right: 5%;
    }

    .contact-form {
    margin-bottom: 40px;
    margin-left: 4%;
    margin-right: auto;
    max-width: 90%;
    align-items: center;
}
}