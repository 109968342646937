.navbar {
  background: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  height: 40px;
  width: 200px;
  margin-top: 22px;
}

.navbar-icon {
  margin-right: 0.5rem;
  max-width: 100%;
  max-height: 100%;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-start; /*flex-end*/
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #2ea7cd;
}

.nav-links {
  color: #1c2769;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.menu-icon {
  display: none;
}

@media screen  and (min-width: 414px) and (max-width: 700px) {
  .NavbarItems {
      position: relative;
  }

  .nav-menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start; /*end or flex-end */
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
  }

  .navbar-logo {
    display: flex;
    position: relative;
    top:  0;
    left: 0;
    transform: translate(50%, 0%);
  }

  .nav-menu.active {
      background: white;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
  }

  .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
  }

  .nav-links:hover{
      color: #2ea7cd;
      transform: scale(1.2);
      transition: all 0.3s ease;
  }

  .nav-item:hover {
      border: none;
  }

  .nav-item {
      width: 100%;
  }

  .menu-icon {
      display: block;
      position: absolute;
      top:  0;
      right: 0;
      transform: translate(-100%, 50%);
      font-size: 1.8rem;
      cursor: pointer;
  }
  .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
  }
}

@media screen  and (min-width: 0px) and (max-width: 414px) {

  .NavbarItems {
    position: relative;
  }

.nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /*end or flex-end */
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
}

.navbar-logo {
  display: flex;
  position: relative;
  top:  0;
  left: 0;
  transform: translate(25%, 0%);
}

.nav-menu.active {
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
}

.nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
}

.nav-links:hover{
    color: #2ea7cd;
    transform: scale(1.2);
    transition: all 0.3s ease;
}

.nav-item:hover {
    border: none;
}

.nav-item {
    width: 100%;
}

.menu-icon {
    display: block;
    position: absolute;
    top:  0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    cursor: pointer;
}

.fa-times {
    color: #fff;
    font-size: 2rem;
}

.nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
}
}


