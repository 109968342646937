@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;600&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
:root {
    --primary: #1c2769;   
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn--primary {
    background-color: #1c2769;
    background-color: var(--primary);
    color: #fff;
    border: 1px solid #1c2769;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #1c2769;
    padding: 8px 20px;
    border: 1px solid #1c2769;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--small {
    padding: 5px 10px;
    font-size: 15px;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 24px;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}

.btn--wide {
    padding: 12px 100px;
    font-size: 20px;
    
}

.btn--large:hover, .btn--medium:hover, .btn--mobile:hover, .btn--small:hover {
    transition: all 0.3s ease-out;
    background: #1b2e6e;
    color: #fff;
}

.btn--wide:hover {
    color: #fff;
    background-color: #1b2e6e;
    transition: all 0.2s ease-out;
}

.blue {
    background-color: #276afb;
    color: #fff;
    border: none;
}

.red {
    background-color: #f00946;
    color: #fff;
    border: none;
}

.primary {
    background-color: #242424;
    color: #fff;
    border: none;
}

.primary:hover {
    background-color: #fff;
    color: #242424;
    border: none;
}

.green {
    background-color: #25ce4a;
    color: #fff;
    border: none;
}

.green:hover {
    background-color: #242424;
}

.btn-link {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
    padding: 8px;
    height: 100%;
    width: 100%;
}
.navbar {
  background: white;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 80px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 40px;
  width: 200px;
  margin-top: 22px;
}

.navbar-icon {
  margin-right: 0.5rem;
  max-width: 100%;
  max-height: 100%;
}

.nav-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  list-style: none;
  text-align: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; /*flex-end*/
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #2ea7cd;
}

.nav-links {
  color: #1c2769;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.menu-icon {
  display: none;
}

@media screen  and (min-width: 414px) and (max-width: 700px) {
  .NavbarItems {
      position: relative;
  }

  .nav-menu {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; /*end or flex-end */
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
  }

  .navbar-logo {
    display: -webkit-flex;
    display: flex;
    position: relative;
    top:  0;
    left: 0;
    -webkit-transform: translate(50%, 0%);
            transform: translate(50%, 0%);
  }

  .nav-menu.active {
      background: white;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
  }

  .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
  }

  .nav-links:hover{
      color: #2ea7cd;
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      transition: all 0.3s ease;
  }

  .nav-item:hover {
      border: none;
  }

  .nav-item {
      width: 100%;
  }

  .menu-icon {
      display: block;
      position: absolute;
      top:  0;
      right: 0;
      -webkit-transform: translate(-100%, 50%);
              transform: translate(-100%, 50%);
      font-size: 1.8rem;
      cursor: pointer;
  }
  .nav-btn {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      width: 100%;
      height: 120px;
  }
}

@media screen  and (min-width: 0px) and (max-width: 414px) {

  .NavbarItems {
    position: relative;
  }

.nav-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; /*end or flex-end */
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
}

.navbar-logo {
  display: -webkit-flex;
  display: flex;
  position: relative;
  top:  0;
  left: 0;
  -webkit-transform: translate(25%, 0%);
          transform: translate(25%, 0%);
}

.nav-menu.active {
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
}

.nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
}

.nav-links:hover{
    color: #2ea7cd;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all 0.3s ease;
}

.nav-item:hover {
    border: none;
}

.nav-item {
    width: 100%;
}

.menu-icon {
    display: block;
    position: absolute;
    top:  0;
    right: 0;
    -webkit-transform: translate(-100%, 50%);
            transform: translate(-100%, 50%);
    font-size: 1.8rem;
    cursor: pointer;
}

.fa-times {
    color: #fff;
    font-size: 2rem;
}

.nav-btn {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 120px;
}
}



/*Home Page CSS*/

.banner-top{
  width: 100%;
  height: auto;
  min-height: 500px;
  overflow: hidden;
  position: relative;
}

.info-top {
  position: absolute;
  font-weight: 200;
  font-size: 1.4rem;
  top: calc(0% + 140px);
  padding-left: 5%;
  padding-right: 5%;
  /* left: calc(0% + 10%); */
  text-align: center;
  width: 100%;
  text-shadow: 0.02em 0 lightgray, 0 0.02em lightgray;
}

.info-top p {
  text-transform: uppercase;
}

.banner {
  width: 100%;
  height: auto;
  min-height: 500px;
  overflow: hidden;
  position: relative;
  /* border-bottom: 15px solid #21396c; */
}

.bg-image {
  width: 100%;
  min-width: 900px;
  min-height: 500px;
  height: auto;
  position: absolute;
  /* overflow: hidden; */
}

.color-overlay{
  width: 100%;
  height: 100%;
  background: #2da3c8;
  position: absolute;
  opacity: .15;

  /* border: 3px solid red; */
}

.info-centered {
  position: absolute;
  font-weight: 200;
  color: white;
  top: calc(0% + 30px);
  left: 0;
  text-align: center;
  width: 100%;
  text-shadow: 0.02em 0 lightgray, 0 0.02em lightgray;
}

ul {
  list-style: none;
}

.services-block {
  padding-top: 20px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
  text-align: center;
  color: #1c2769;
  /* background: url('../assets/images/home/grid-background.jpg') repeat; */
  /* background-image: radial-gradient(circle, white, #1e98b6, #32568e); */
}

.grid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
    
}

.block {
  position: relative;
  width: 30%;
  margin: 2%;
  -webkit-flex: 1 1 25%;
          flex: 1 1 25%;
  /* border-radius: 5%; */
  /* border: 2px solid lightblue; */
}

.color-overlay-grid{
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  opacity: .55;
  border-radius: 10%;
  /* border-radius: 40%; */
  /* border: 3px solid red; */
}

.color-overlay-grid:hover {
  z-index: 1;
  background: black;
  opacity: .2;
  transition: .25s;
  transition-timing-function: linear;
}

.image {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  border-radius: 10%;
  /* border-radius: 40%; */
}

.grid-text {
  position: absolute;
  font-weight: 400;
  font-size: larger;
  color: white; 
  left: 50%;                        
  top: 50%;                         
  -webkit-transform: translate(-50%, -50%);                         
          transform: translate(-50%, -50%); 
}

@media only screen and (max-width: 700px) {

  .info-top {
    font-size: 1.2rem;
  }

  .services-block{
    padding-left: 1%;
    padding-right: 1%;
      
  }

  .grid-text{
    color: black;                        
    -webkit-transform: translate(-10%, -50%);                        
            transform: translate(-10%, -50%); 
  }

  .block {
    -webkit-flex: 1 1 100%;
            flex: 1 1 100%;
    /* width: 100%; */
    height: 6rem;
    border-radius: 20%;
  }

  .image{
    /* height: 10rem; */
    /* width: fit-content; */
    height: 6rem;
    width: auto;
    border-radius: 30px;
    /* border-bottom-right-radius: 0px;
    border-top-right-radius: 0px; */
  }
    
  .color-overlay-grid{
    border-radius: 30px;
    opacity: .4;
  }

}


  



 

  

.gallery-title {
    color: #1c2769;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    text-shadow: 0.02em 0 lightgray, 0 0.02em lightgray;
  }
.contact-title {
    color: #1c2769;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    text-shadow: 0.02em 0 lightgray, 0 0.02em lightgray;
  }

.contact-form {
    margin-bottom: 40px;
    margin-left: 4%;
    margin-right: auto;
    max-width: 90%;
    -webkit-align-items: center;
            align-items: center;
}
  
.form-control {    
    margin: 20px 0;
}
  
.form-control label {
    display: block;
}
  
.form-control input {
    width: 100%;
    height: 40px;
    margin: 5px;
    padding: 3px 7px;
    font-size: 17px;
}
  
.form-control-check {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
  
.form-control-check label {
    -webkit-flex: 1 1;
            flex: 1 1;
}
  
.form-control-check input {
    -webkit-flex: 2 1;
            flex: 2 1;
    height: 20px;
}

@media screen and (min-width: 415px){
    .contact-container {
        margin-left: 5%;
        margin-right: 5%;
    }

    .contact-form {
    margin-bottom: 40px;
    margin-left: 4%;
    margin-right: auto;
    max-width: 90%;
    -webkit-align-items: center;
            align-items: center;
}
}
